import type { IRequestManager } from '@@services/apiaccessprovider.definition';
import type { IMember } from '@@community/data/index';
import { defaultPostHeaderForJsonData } from '@@apifront/index';
import type { IUserAutofillResult } from '@@components/userautofill';

export function getMe(requestManager: IRequestManager, communityId: string): Promise<IMember> {
    return requestManager.postJson("/community/" + communityId + "/member/me", JSON.stringify({
        selects: {
            $all: true,
            membershipLevel: {
                id: true,
                name: true,
                configuration: true
            },
            groupmembers: {
                status: true,
                isModerator: true,
                group: {
                    id: true,
                    title: true
                },
                groupId: true
            },
            companyAccounts: {
                $all: true,
                company: {
                    id: true,
                    name: true,
                    logo: true
                }
            }
        }
    }), defaultPostHeaderForJsonData).then((res: any) => res);
}

export function updateMe(requestManager: IRequestManager, communityId: string, info: any): Promise<IMember> {
    return requestManager.postJson(
        "/community/" + communityId + "/member/me/edit",
        JSON.stringify(info),
        defaultPostHeaderForJsonData
    );
}


export function getMyLinkedinData(
    requestManager: IRequestManager,
    communityId: string,
    linkedin: string
): Promise<IUserAutofillResult> {

    const encodedLinkedin = encodeURIComponent(linkedin);
    const data = requestManager.getJson(
        "/community/" + communityId + `/member/me/linkedin-data?vanityName=${encodedLinkedin}`
    );

    return data.then((res: any) => {
        if (res) {
            return {
                description: res.description,
                linkedin: res.linkedinProfileUrl,
                photo: res.photoUrl
            };
        }
        return null;
    });
}

export function uploadMyLinkedinImage(
    requestManager: IRequestManager,
    communityId: string,
    url: string
): Promise<any> {
    return requestManager.postJson(
        "/community/" + communityId + `/member/me/linkedin-data/upload-image`,
        JSON.stringify({
            url: url,
            name: "photo"
        }), defaultPostHeaderForJsonData);
}


export function canNetwork(requestManager: IRequestManager, communityId: string, memberIds: string[]): Promise<any> {
    return requestManager.postJson(
        "/community/" + communityId + "/member/me/cannetwork",
        JSON.stringify({ memberIds }),
        defaultPostHeaderForJsonData
    );
}

export function requestDeleteMe(requestManager: IRequestManager, communityId: string) {
    return requestManager.postJson(
        "/community/" + communityId + '/member/me/request-delete',
        '{}',
        defaultPostHeaderForJsonData
    );
}