import { States } from '@@services/services';
import { getMe } from '../../api/me';
import type { Entities } from '@inwink/entities/entities';
import type { IProfileModalProps } from '@@modules/community/components/appheader/profile/appheader.profilemodal';
import { userMessageActions } from '@@services/usermessageactions';
import type { IInwinkStore } from '@@store/index';
import { IPopoverManager } from '@inwink/modals/popovermgr';

const _g = global as any;

export const currentUserActions = {
    signout() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            state.community.requestManagers.userActions.signOut().then(() => {
                // Don't reload if firstpartycookies as the do a page redirect that must not be interrupted by a page reload
                if (inwink.config.authMode !== "firstpartycookies") {
                    window.location.reload();
                }
                // dispatch({
                //     type: "USER_SETCURRENT",
                //     payload: {
                //         detail: null,
                //         member: null
                //     }
                // });
            });
        };
    },
    showProfile: (i18nHelper: Entities.i18nHelper, store: IInwinkStore, history: History, popovermgr: IPopoverManager, 
        target: HTMLElement) => {
        return (dispatch, getState) => {
            return import("@@modules/community/components/appheader/profile/appheader.profilemodal").then((mod) => {
                const state: States.IAppState = getState();
                const props: IProfileModalProps = {
                    i18nHelper,
                    store,
                    user: state.user,
                    community: state.community,
                    history: history,
                    popovermgr,
                    animate: true
                };

                return userMessageActions.showMessage(
                    i18nHelper,
                    mod.ProfileModal,
                    props,
                    target,
                    null,
                    null,
                    "profile-modal"
                )(dispatch);
            });
        };
    },

    setCurrentUser(user: any) {
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({
                type: "USER_SETCURRENT",
                payload: {
                    detail: user,
                    member: user?.id ? user : null
                }
            });
            dispatch({
                type: "USER_SYNCREADY"
            });
            dispatch({
                type: "USER_CHECKED"
            });

            const state = getState() as States.IAppState;
            if (_g.localStorage && user.id) {
                _g.localStorage[state.community.communityid + "-currentuser"] = user.id;
            }
        };
    },

    checkCurrentUser(): (dispatch, getState: () => States.IAppState) => Promise<any> {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            return storedata.community.requestManagers.initPromise.then(() => {
                return storedata.community.requestManagers.userActions.localUser().then(() => {
                    return getMe(storedata.community.requestManagers.apiFront, storedata.community.communityid).then((mydata) => {
                        // if (mydata && mydata.id) {
                        return currentUserActions.setCurrentUser(mydata)(dispatch, getState);
                        // }
                    }, () => {
                        dispatch({
                            type: "USER_CHECKED"
                        });
                        dispatch({
                            type: "USER_SYNCREADY"
                        });
                        return null;
                    });
                });
            });
        };
    }
};
